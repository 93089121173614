
import { defineComponent } from "vue";

export default defineComponent({
  name: "Projects",
  data() {
    return {
      projects: [
        {
          id: "3thehives",
          title: "Set design and costumes for world tour",
          date: "2021 - 2022 - 2023",
          logo: "thehives.png",
          activeMediaIndex: 0,
          media: [
            { name: "1.jpeg", type: "img" },
            { name: "2.jpeg", type: "img" },
            { name: "3.jpeg", type: "img" },
            { name: "4.jpeg", type: "img" },
            { name: "5.jpeg", type: "img" },
          ],
        },
        {
          id: "1nordiskagalleriet",
          title: "Mural inferno - Exhibiton",
          date: "November - February 2022/23",
          logo: "nordiskagalleriet.png",
          activeMediaIndex: 0,
          media: [
            { name: "1.jpeg", type: "img" },
            { name: "2.jpeg", type: "img" },
            { name: "3.jpeg", type: "img" },
          ],
        },
        {
          id: "2zalando",
          title: "Live painting during Way Out West",
          date: "August 2022",
          logo: "zalando.png",
          activeMediaIndex: 0,
          media: [
            { name: "1.jpeg", type: "img" },
            { name: "2.jpeg", type: "img" },
            { name: "3.jpeg", type: "img" },
            { name: "4.jpeg", type: "img" },
          ],
        },
        {
          id: "4gantxdiemme",
          title: "Artwork for Gant x Diemme release party",
          date: "January 2022",
          logo: "gantxdiemme.png",
          activeMediaIndex: 0,
          media: [
            { name: "1.jpeg", type: "img" },
            { name: "2.jpeg", type: "img" },
          ],
        },
        {
          id: "5obaren",
          title: "Interior paintings for Sturehofs night club Obaren in Stockholm",
          date: "December 2021",
          logo: "obaren.png",
          activeMediaIndex: 0,
          media: [
            { name: "1.jpg", type: "img" },
            { name: "2.jpg", type: "img" },
            { name: "3.jpeg", type: "img" },
            { name: "4.jpeg", type: "img" },
            { name: "5.jpg", type: "img" },
          ],
        },
        {
          id: "6misschiefs",
          title: "Solo exhibition at MissChiefs gallery in Stockholm",
          date: "October 2021",
          logo: "misschiefs.png",
          activeMediaIndex: 0,
          media: [
            { name: "1.jpeg", type: "img" },
            { name: "2.jpg", type: "img" },
            { name: "3.jpg", type: "img" },
            { name: "4.jpg", type: "img" },
            { name: "5.jpg", type: "img" },
          ],
        },
        {
          id: "7privateapartment",
          title: "Apartment fully decorated in Studio Igor Ingrid patterns for IggyJeans SS22 show",
          date: "August 2021",
          logo: "privateapartment.png",
          activeMediaIndex: 0,
          media: [
            { name: "1.jpg", type: "img" },
            { name: "2.jpeg", type: "img" },
            { name: "3.jpeg", type: "img" },
            { name: "4.jpeg", type: "img" },
          ],
        },
        {
          id: "8nordiskakompaniet",
          title: "Denim backdrop for IggyJeans pop up at Nordiska Kompagniet",
          date: "April 2021",
          logo: "nordiskakompaniet.png",
          activeMediaIndex: 0,
          media: [
            { name: "1.jpeg", type: "img" },
            { name: "2.jpg", type: "img" },
          ],
        },

        // {
        //   id: "obaren",
        //   title: "Obaren",
        //   logo: "",
        //   activeMediaIndex: 0,
        //   media: [
        //     { name: "1.jpeg", type: "img" },
        //     { name: "2.jpeg", type: "img" },
        //     { name: "linus.mp4", type: "video" },
        //   ],
        // },
      ],
    };
  },
  methods: {
    previousSlide(projectIndex: number) {
      let project = this.projects[projectIndex];
      if (project.activeMediaIndex == 0) {
        this.projects[projectIndex].activeMediaIndex = project.media.length - 1;
      } else {
        this.projects[projectIndex].activeMediaIndex--;
      }
    },
    nextSlide(projectIndex: number) {
      let project = this.projects[projectIndex];
      if (project.activeMediaIndex == project.media.length - 1) {
        this.projects[projectIndex].activeMediaIndex = 0;
      } else {
        this.projects[projectIndex].activeMediaIndex++;
      }
    },
  },
});
